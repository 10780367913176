<template>
  <div>
     <!-- <br><template v-if="tab == 2"><br><br><br><br><br><br><br><br></template> -->
    <center>
      <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''" >
      <br><br>
      <b-row class="justify-content-center mt-5"  >
        <b-col cols="10" md="2">
              <v-btn
                class="ma-2"
                :color="tab == 1 ? 'info' : 'blue-grey'"
                style="text-transform: none;"
                block
                @click="cambiarTab(1)"
              >
                Reportes
              </v-btn>
        </b-col>
        <b-col cols="10" md="2">
              <v-btn
                class="ma-2"
                :color="tab == 2 ? 'info' : 'blue-grey'"
                style="text-transform: none;"
                block
                @click="cambiarTab(2)"
              >
                Ganadores
              </v-btn>
        </b-col>
      </b-row>
      <!-- <h4 style="color:#fff;" class="text-center mt-5">Descargar Reportes</h4> -->
     
      <b-row class="justify-content-center mt-5">
        <b-col cols="12" md="6">
          
         <b-row class="justify-content-center" v-if="tab == 1">
            <b-col cols="12" md="4">
               <br><template v-if="!isMobile"><br><br><br></template>
               <img src="elementos/propio.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:50%;' : 'width:40%;'"> 
               <br>
               <h5  class="text-white  mt-3"><b>Canal Propio</b></h5>
               <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="descargaReporte()">Descargar <b-spinner v-if="loader" small></b-spinner></u>
            </b-col>
            <b-col cols="12" md="4">
              <br><template v-if="!isMobile"><br><br><br></template>
               <img  src="elementos/retail.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:50%;' : 'width:40%;'">
               <br>
               <h5  class="text-white  mt-3" ><b>Canal Retail</b></h5>
               <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="descargaReporte1()">Descargar <b-spinner v-if="loader2" small></b-spinner></u>
            </b-col>
            <b-col cols="12" md="4">
              <br><template v-if="!isMobile"><br><br><br></template>
              <img src="elementos/tele.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:50%;' : 'width:40%;'">
              <br>
              <h5  class="text-white  mt-3"><b>Canal TLMKT</b></h5>
              <u class="mt-5" style="color:#5cb617; cursor: pointer;" @click="descargaReporte2()">Reporte <b-spinner v-if="loader3" small></b-spinner></u>
            </b-col>
         </b-row>

          <b-row class="justify-content-center" v-if="tab == 2 && canal == 0">
            <b-col cols="12" md="12">
              <br>
                 <h2 class="text-white">¡Muchas felicidades a los ganores!</h2>
                 <h4 class="text-white mt-5"> <b style="color:#5cb617;">INQUEBRANTABLES 2022</b> felicita a nuestros colaboradores por su esfuerzo, 
                  <b style="color:#5cb617;"> y ¡nos vemos en el 2023!</b>
                 </h4>
            </b-col>
            <b-col cols="12" md="4">
               <br><template v-if="!isMobile"><br></template>
               <img src="elementos/star.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:35%;' : 'width:20%;'"> 
               <br><br>
               <h2 class="text-white  mt-3">Ganadores</h2>
               <h5  class="text-white  mt-3"><b>Canal Propio</b></h5>
               <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="buscaGanadores(3)" >Conócelos </u>
            </b-col>
            <b-col cols="12" md="4">
              <br><template v-if="!isMobile"><br></template>
               <img  src="elementos/star.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:35%;' : 'width:20%;'">
               <br><br>
               <h2 class="text-white  mt-3">Ganadores</h2>
               <h5  class="text-white  mt-3" ><b>Canal Retail</b></h5>
               <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="buscaGanadores(1)">Conócelos </u>
            </b-col>
            <b-col cols="12" md="4">
              <br><template v-if="!isMobile"><br></template>
              <img src="elementos/star.png" :class="!isMobile ? 'rounded' :'rounded'" alt="Image" :style="!isMobile ? 'width:35%;' : 'width:20%;'">
              <br><br>
              <h2 class="text-white  mt-3">Ganadores</h2>
              <h5  class="text-white  mt-3"><b>Canal TLMKT</b></h5>
              <u class="mt-5" style="color:#5cb617; cursor: pointer;" @click="buscaGanadores(2)">Conócelos </u>
            </b-col>
         </b-row>

         <template v-if="tab == 2 && canal != 0">
            
            <!-- <b-col cols="12" md="12"> -->
              <br>
                 <h2 class="text-white">¡Muchas felicidades a los ganadores!</h2>
                 <h4 class="text-white mt-5"> <b style="color:#5cb617;">INQUEBRANTABLES 2022</b> felicita a nuestros colaboradores por su esfuerzo, 
                  <b style="color:#5cb617;"> y ¡nos vemos en el 2023!</b>
                 </h4>
            <!-- </b-col> -->
            <br>
            <u class="mt-3" style="color:#5cb617; cursor: pointer;" @click="canal = 0" >Regresar</u>
              <b-row class="justify-content-center mt-5" v-if="canal == 3">
                <b-col cols="10" md="8" >
                    <br><br>

                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <h4 style="color:#5cb617;">EJECUTIVOS</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Ejecutivo'">
                                {{gan.nombre}}
                            </p>
                        </div>
                        <div class="carousel-item" >
                                <h4 style="color:#5cb617;">GERENTES</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Gerente'">
                                {{gan.nombre}}
                            </p>
                        </div>
                        <div class="carousel-item" >
                            <h4 style="color:#5cb617;">COORDINADORES</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Coordinador'">
                                {{gan.nombre}}
                            </p>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                    
       
                </b-col>
            </b-row>

            <b-row class="justify-content-center mt-2" v-if="canal == 2">
                <b-col cols="10" md="4" >
                    <br><br>
                    <h4 style="color:#5cb617;">CONCURSANTES</h4>
                    <hr>
                    <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" >
                        {{gan.nombre}}
                    </p>
                </b-col>
            </b-row>

            <b-row class="justify-content-center mt-2" v-if="canal == 1">
                    <b-col cols="10" md="8" >
                        <br><br>
                      <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                            <div class="carousel-item active">
                            <h4 style="color:#5cb617;">PROMOTORES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'PROMOTOR'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                            <div class="carousel-item"  >
                                <h4 style="color:#5cb617;">SUPERVISORES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'SUPERVISOR'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                            <div class="carousel-item"  >
                                <h4 style="color:#5cb617;">LIDERES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'LIDER'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                        </div>
  
                        </div>
                    
                    </b-col>
            </b-row>

          </template>
        </b-col>
      </b-row>
    </center>
    <br><br>
  </div>
</template>
<script>
// import Swal from 'sweetalert2'
export default {
  name: 'Embajador',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      loader2:false,
      loader3:false,
      tab:1,
      canal:0,
      arrayGan:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    descargaReporte(){
        // Swal.fire({
        //     title: 'Estas seguro de descargar el reporte?',
        //     text: "Este proceso puede tardar varios minutos. " +
        //                 "Mientras tanto lánzate por un aperitivo." +
        //                 " Mantén tu navegador abierto hasta que termine",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#5cb617',
        //     cancelButtonColor: '#187abe',
        //     confirmButtonText: 'Descargar',
        //     cancelButtonText:'Cancelar',
        //     }).then((result) => {
        //     if (result.isConfirmed) {
                this.loader = true;
                  this.$fileDownload(
                            this.$api.baseUri + 'auth/propio',
                            "reporte-ranking-propio.xlsx",
                    ).then(
                        () => {
                            this.loader = false;
                        }
                    )
                // var url= 'auth/propio';
                // this.$api.get(url).then(
                //     ({data}) => {
                //         console.log(data);                        
                //         this.loader = false;
                //         // window.open('http://localhost:8080/storage/exceles/reporte-ranking-propio.xlsx','_blank');
                //     }
                // );
        //     }
        // })
    },
    descargaReporte1(){
        // Swal.fire({
        //     title: 'Estas seguro de descargar el reporte?',
        //     text: "Este proceso puede tardar varios minutos. " +
        //                 "Mientras tanto lánzate por un aperitivo." +
        //                 " Mantén tu navegador abierto hasta que termine",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#5cb617',
        //     cancelButtonColor: '#187abe',
        //     confirmButtonText: 'Descargar',
        //     cancelButtonText:'Cancelar',
        //     }).then((result) => {
        //     if (result.isConfirmed) {
                this.loader2 = true;
                  this.$fileDownload(
                            this.$api.baseUri + 'auth/retail',
                            "reporte-ranking-retail.xlsx",
                    ).then(
                        () => {
                            this.loader2 = false;
                        }
                    )
                // var url= 'auth/retail';
                // this.$api.get(url).then(
                //     ({data}) => {
                //         console.log(data);                        
                //         this.loader2 = false;
                //         // window.open('http://localhost:8080/storage/exceles/reporte-ranking-propio.xlsx','_blank');
                //     }
                // );
        //     }
        // })
    },
    descargaReporte2(){
        // Swal.fire({
        //     title: 'Estas seguro de descargar el reporte?',
        //     text: "Este proceso puede tardar varios minutos. " +
        //                 "Mientras tanto lánzate por un aperitivo." +
        //                 " Mantén tu navegador abierto hasta que termine",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#5cb617',
        //     cancelButtonColor: '#187abe',
        //     confirmButtonText: 'Descargar',
        //     cancelButtonText:'Cancelar',
        //     }).then((result) => {
            // if (result.isConfirmed) {
                this.loader3 = true;
                this.$fileDownload(
                            this.$api.baseUri + 'auth/tlmkt',
                            "reporte-ranking-tlmkt.xlsx",
                  ).then(
                      () => {
                          this.loader3 = false;
                      }
                  )
                // var url= 'auth/tlmkt';
                // this.$api.get(url).then(
                //     ({data}) => {
                //         console.log(data);                        
                //         this.loader3 = false;
                //         // window.open('http://localhost:8080/storage/exceles/reporte-ranking-propio.xlsx','_blank');
                //     }
                // );
        //     }
        // })
    },
    cambiarTab(id){
      this.tab = id;
  
       
    },
    buscaGanadores(id){
        var url= 'auth/gana?canal='+ id;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.arrayGan = data.ganadores;      
                    this.canal = id;     
                    this.$confetti.start({
                          particles: [
                              {
                              type: 'rect',
                              },
                              {
                              type: 'circle',
                              },
                          ],
                          defaultColors: [
                              '#5cb617',
                              '#ffffff',
                              '#187abe'
                          ],
                      });
                      setTimeout(() => {
                          this.$confetti.stop();
                      }, 4000);      
                }
            );
    },
    regresarHome(){
      this.$bus.$emit("cambia_fondo", true)
      location.reload();
    }
  },
}
</script>